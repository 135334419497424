import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StunServiceService {

  constructor() { }

  async getURLS():Promise<any> {
    return new Promise( (resolve, reject) => {
      try {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function($evt){
          try {
            if(xhr.readyState == 4 && xhr.status == 200){
              let res = JSON.parse(xhr.responseText);
              const stunArray = [
                "stun:stun.business-isp.nl:3478",
                "stun:stun.vomessen.de:3478",
                "stun:stun.symonics.com:3478",
                "stun:stun.bitburger.de:3478",
                "stun:stun.l.google.com:19302"
              ];
                res.v.iceServers.urls.unshift(...stunArray);
              console.log(res)
              resolve(res);
            }
          } catch(e) {
            console.log(e);
          }
          
        }
        xhr.open("PUT", "https://global.xirsys.net/_turn/MyFirstApp", true);
        xhr.setRequestHeader ("Authorization", "Basic " + btoa("deadener:225b9c68-70eb-11ed-aa8f-0242ac150003") );
        xhr.setRequestHeader ("Content-Type", "application/json");
        
        xhr.send( JSON.stringify({"format": "urls"}) );
      } catch(e) {
        alert('Error getting P2P Server URLS.')
        console.log(e);
        resolve(null)
      }
     
    })
    
  }
}
